import { getCurrentLocale } from '@leagueplatform/locales';

export const getLocaleConstant = (localeOptions: {
  en: string;
  es: string;
}) => {
  const currentLocale = getCurrentLocale();
  const localeLanguage = currentLocale.toLowerCase().split('-')[0];

  if (localeLanguage === 'en' || localeLanguage === 'es') {
    return localeOptions[localeLanguage];
  }

  return localeOptions.en;
};
