import { StackLayout } from '@leagueplatform/genesis-core';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hook';

import { Logo } from 'components/logo.component';
import { LoadingSpinner } from './loading-spinner.component';

export const FullPageLoadingSpinner = () => {
  const isBelowTablet = useIsBelowTablet();

  return (
    <FullScreenContainer>
      <StackLayout
        orientation="vertical"
        horizontalAlignment="center"
        verticalAlignment="center"
        css={{ height: '100%' }}
      >
        {!isBelowTablet && (
          <Logo width="433px" css={{ paddingBlockEnd: '$four' }} />
        )}
        <LoadingSpinner />
      </StackLayout>
    </FullScreenContainer>
  );
};
