import React from 'react';
import {
  StackLayout,
  Box,
  Button,
  type GDSButtonProps,
  type GDSStyleObject,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { ResponsiveButtonRow } from 'components/responsive-button-row/responsive-button-row.component';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hook';

type CTAFooterProps = {
  showPrimaryBtn?: boolean;
  showSecondaryBtn?: boolean;
  primaryBtnLabel?: GDSButtonProps<'button'>['children'];
  primaryBtnProps?: Omit<GDSButtonProps<'button'>, 'children'>;
  secondaryBtnLabel?: GDSButtonProps<'button'>['children'];
  secondaryBtnProps?: Omit<GDSButtonProps<'button'>, 'children'>;
  contentWidth?: string;
  css?: GDSStyleObject;
} & ({ showPrimaryBtn: true } | { showSecondaryBtn: true });

export const CTAFooter = ({
  showPrimaryBtn,
  primaryBtnLabel,
  primaryBtnProps,
  showSecondaryBtn,
  secondaryBtnLabel,
  secondaryBtnProps,
  contentWidth = '1024px',
  css,
  ...props
}: CTAFooterProps) => {
  const { formatMessage } = useIntl();
  const isBelowTablet = useIsBelowTablet();
  return (
    <Box
      css={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        backgroundColor: '$surfaceCardSecondary',
        padding: '$oneAndHalf',
        boxShadow: '$card',
        ...css,
      }}
      {...props}
    >
      <StackLayout
        orientation="horizontal"
        horizontalAlignment="end"
        spacing="$oneAndHalf"
        css={{
          maxWidth: contentWidth,
          marginInline: 'auto',
        }}
      >
        <ResponsiveButtonRow
          primaryBtn={
            showPrimaryBtn && (
              <Button
                width={isBelowTablet ? 'fillContainer' : undefined}
                {...primaryBtnProps}
              >
                {primaryBtnLabel || formatMessage({ id: 'STR_CONTINUE' })}
              </Button>
            )
          }
          secondaryBtn={
            showSecondaryBtn && (
              <Button
                priority="secondary"
                width={isBelowTablet ? 'fillContainer' : undefined}
                {...secondaryBtnProps}
              >
                {secondaryBtnLabel || formatMessage({ id: 'STR_CANCEL' })}
              </Button>
            )
          }
        />
      </StackLayout>
    </Box>
  );
};
