import React from 'react';
import {
  Card,
  HeadingText,
  ParagraphText,
  StackLayout,
  useClickableCard,
  Icon,
} from '@leagueplatform/genesis-core';
import {
  MasonryEngineNodeRendererProps,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import {
  type MasonryDeeplinkAction,
  type MasonryDriverAction,
} from '@leagueplatform/masonry-renderers';
import { OpensInANewTabMessage } from '@leagueplatform/web-common-components';
import { useMasonryEngineActionEmitter } from '@leagueplatform/masonry-engine';
import type { NotificationTypes } from 'common/types';
import { NotificationTypeBadge } from 'components/notifications/notification-type-badge.component';
import { InternalOrExternalTextAction } from 'components/notifications/internal-or-external-text-action.component';

type NotificationCardNodeProperties = {
  heading: string;
  body: string;
  notificationType: NotificationTypes;
  badgeText: string;
  actionLink: string;
  isExternalLink: boolean;
};

type NotificationCardNodeActions = {
  onClick: MasonryDriverAction;
};

type NotificationCardNode = MasonryEngineNode<
  'ovatientNotificationCard',
  NotificationCardNodeProperties,
  NotificationCardNodeActions
>;

type NotificationCardProps =
  MasonryEngineNodeRendererProps<NotificationCardNode>;

export const NotificationCard = ({
  heading,
  body,
  notificationType,
  badgeText,
  isExternalLink,
  onClick,
}: NotificationCardProps) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <Card.Elevated
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '$half',
        boxShadow: '0px 2px 6px 0px #0000001F',
        borderRadius: '8px',
        border: 'none',
        backgroundColor: '$surfaceCardSecondary',
        width: '100%',
      }}
    >
      <Card.ClickArea
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
        imagePosition={{ '@initial': 'end', '@mobile': 'top' }}
        css={{
          flexDirection: 'row',
          alignItems: 'center',
          border: 'none !important',
          paddingInline: '$oneAndHalf',
          paddingBlock: '$oneAndHalf',
          gap: '$quarter',
          height: '100%',
        }}
      >
        <StackLayout css={{ flexGrow: 1, justifyContent: 'center' }}>
          <HeadingText level="3" size="sm">
            <InternalOrExternalTextAction
              isExternalLink={isExternalLink}
              url={(onClick as MasonryDeeplinkAction)?.payload?.url}
              ref={primaryActionRef}
              onClick={(event: React.MouseEvent) => {
                if (onClick) {
                  invokeAction({
                    event,
                    ...onClick,
                  });
                }
              }}
            >
              {heading}
            </InternalOrExternalTextAction>
          </HeadingText>
          <ParagraphText
            css={{
              typography: '$bodyTwo',
              marginBlockEnd: '$none',
              color: '$onSurfaceTextSubdued',
            }}
          >
            {body}
            {isExternalLink && <OpensInANewTabMessage />}
          </ParagraphText>
          <NotificationTypeBadge label={badgeText} type={notificationType} />
        </StackLayout>
        {isExternalLink && (
          <StackLayout css={{ flex: 0, justifyContent: 'center' }}>
            <Icon
              icon="interfaceExternalLink"
              size="$one"
              tint="$onSurfaceTextPrimary"
            />
          </StackLayout>
        )}
      </Card.ClickArea>
    </Card.Elevated>
  );
};
