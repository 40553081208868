import React, { type PropsWithChildren } from 'react';
import { useMachine } from '@xstate/react';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import {
  onboardingMachine,
  OnboardingMachineContext,
} from 'components/onboarding/onboarding.machine';
import { FeatureHighlights } from 'components/onboarding/feature-highlights.component';
import { ConsentView } from 'components/onboarding/views/consent-view.component';
import { CreateProfileView } from 'components/onboarding/views/create-profile-view.component';
import { AllSetView } from 'components/onboarding/views/all-set-view.component';

const OnboardingViews = ({ children }: PropsWithChildren<{}>) => {
  const { send } = OnboardingMachineContext.useActorRef();
  const snapshot = OnboardingMachineContext.useSelector(
    (machineSnapshot) => machineSnapshot,
  );

  if (snapshot.matches({ initializing: 'fetchUserProfileData' })) {
    return <FullPageLoadingSpinner />;
  }

  if (snapshot.matches('featureHighlights')) {
    return (
      <FeatureHighlights
        onOnboardingComplete={() => {
          send({ type: 'featureHighlightsCompleted' });
        }}
      />
    );
  }

  if (snapshot.matches({ userSetup: 'createProfile' })) {
    return <CreateProfileView />;
  }

  if (snapshot.matches({ userSetup: 'consent' })) {
    return <ConsentView />;
  }

  if (snapshot.matches({ userSetup: 'allSet' })) {
    return <AllSetView />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export const Onboarding = ({ children }: PropsWithChildren<{}>) => {
  useMachine(onboardingMachine);

  return (
    <OnboardingMachineContext.Provider>
      <OnboardingViews>{children}</OnboardingViews>
    </OnboardingMachineContext.Provider>
  );
};
