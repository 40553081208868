import { Suspense } from 'react';
import { BrowserRouter } from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { useOnlineStatus } from '@leagueplatform/web-common';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import League, { LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { config } from 'init-config';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import AppRoutes from './components/AppRoutes';
import { FullPageError } from './components/error/full-page-error.component';

League.overwriteRoutePaths({
  [LEAGUE_MODULE_NAMES.assessment]: '/member/health-profile/questions',
  [LEAGUE_MODULE_NAMES.aboutMe]: '/member/about-me',
  [LEAGUE_MODULE_NAMES.healthProfile]: '/member/health-profile',
  [LEAGUE_MODULE_NAMES.healthNeedPages]: '/member/health-need',
  [LEAGUE_MODULE_NAMES.onboarding]: '/member/onboarding',
  [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
  [LEAGUE_MODULE_NAMES.careDocuments]: '/care/documents',
  [LEAGUE_MODULE_NAMES.messaging]: '/messaging',
});

export function App() {
  return (
    <League.LeagueProvider config={config}>
      <ErrorBoundary
        fallback={
          <FullPageError
            errorType={ErrorTypes.API_ERROR}
            buttonMessageId="STR_RELOAD"
            buttonOnClick={() => window.location.reload()}
          />
        }
      >
        <Suspense fallback={<FullPageLoadingSpinner />}>
          {useOnlineStatus().isOffline && (
            <FullPageError errorType={ErrorTypes.NO_CONNECTION} />
          )}
          {useOnlineStatus().isOnline && (
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          )}
          <ReactQueryDevtools />
        </Suspense>
      </ErrorBoundary>
    </League.LeagueProvider>
  );
}
