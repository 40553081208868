import { useIntl } from '@leagueplatform/locales';
import type { LocalesKey } from 'common/types';

export enum ErrorTypes {
  NOT_FOUND_ERROR = 'not_found_error',
  API_ERROR = 'api_error',
  NO_CONNECTION = 'no_connection',
}

export const useErrorMessage = (errorType: ErrorTypes) => {
  const { formatMessage } = useIntl();

  type ErrorBody = {
    headingId: LocalesKey;
    captionId: LocalesKey;
  };

  const ErrorMessages: Record<ErrorTypes, ErrorBody> = {
    [ErrorTypes.NOT_FOUND_ERROR]: {
      headingId: 'STR_ERROR_HEADING_NOT_FOUND',
      captionId: 'STR_ERROR_CAPTION_NOT_FOUND',
    },
    [ErrorTypes.NO_CONNECTION]: {
      headingId: 'STR_ERROR_HEADING_GENERIC',
      captionId: 'STR_ERROR_CAPTION_NO_CONNECTION',
    },
    [ErrorTypes.API_ERROR]: {
      headingId: 'STR_ERROR_HEADING_GENERIC',
      captionId: 'STR_ERROR_CAPTION_BACKEND',
    },
  };

  return {
    errorHeading: formatMessage({ id: ErrorMessages[errorType].headingId }),
    errorCaption: formatMessage({ id: ErrorMessages[errorType].captionId }),
  };
};
