import { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';

export const AuthViewLogout = () => {
  useEffect(() => {
    StandaloneAuth.client.logout({
      returnTo: window.location.origin,
    });
  }, []);

  return <FullPageLoadingSpinner />;
};

export const AuthViewLogin = () => {
  useEffect(() => {
    StandaloneAuth.client.loginWithRedirect();
  }, []);

  return <FullPageLoadingSpinner />;
};
