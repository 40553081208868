import { MouseEventHandler } from 'react';
import {
  StackLayout,
  Button,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import type { LocalesKey } from 'common/types';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import { ErrorTypes, useErrorMessage } from 'hooks/use-error-message.hook';
import errorIcon from 'assets/error-icon.svg';

export const FullPageError = ({
  errorType,
  buttonOnClick,
  buttonMessageId,
}: {
  errorType: ErrorTypes;
  buttonOnClick?: MouseEventHandler<HTMLButtonElement>;
  buttonMessageId?: LocalesKey;
}) => {
  const { errorHeading, errorCaption } = useErrorMessage(errorType);
  const { formatMessage } = useIntl();

  return (
    <FullScreenContainer>
      <StackLayout
        orientation="vertical"
        horizontalAlignment="center"
        verticalAlignment="center"
        css={{
          height: '100%',
          whiteSpace: 'pre-wrap',
        }}
      >
        <Image
          src={errorIcon}
          alt=""
          width="138px"
          height="auto"
          position="relative"
          top="minusOne"
          marginBottom="two"
        />
        <HeadingText
          level="2"
          size="xl"
          css={{
            marginBottom: '$half',
          }}
        >
          {errorHeading}
        </HeadingText>
        <ParagraphText emphasis="subtle" css={{ textAlign: 'center' }}>
          {errorCaption}
        </ParagraphText>
        {buttonOnClick && buttonMessageId && (
          <Button css={{ marginTop: '$two' }} onClick={buttonOnClick}>
            {formatMessage({ id: buttonMessageId })}
          </Button>
        )}
      </StackLayout>
    </FullScreenContainer>
  );
};
