import * as React from 'react';
import { Box } from '@leagueplatform/genesis-core';
import { ROUTE_PATHS } from 'common/constants';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import { FullPageError } from 'components/error/full-page-error.component';

function ErrorPage() {
  return (
    <Box
      css={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <FullPageError
        errorType={ErrorTypes.NOT_FOUND_ERROR}
        buttonMessageId="STR_GO_TO_HOMEPAGE"
        buttonOnClick={() => {
          window.location.href = ROUTE_PATHS.HOME;
        }}
      />
    </Box>
  );
}

export default ErrorPage;
