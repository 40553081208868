import {
  MasonryDriverNodeRenderersRegistry,
  MasonryDriverActionController,
} from '@leagueplatform/masonry';
import {
  MASONRY_NAMESPACES,
  MASONRY_WIDGET_TYPES,
  MASONRY_ACTION_TYPES,
} from 'common/constants/masonry';
import { NotificationCard } from 'components/notifications/renderers/notification-card.renderer';
import { NotificationDetailsRenderer } from 'components/notifications/renderers/notification-details.renderer';

export const initNotificationsRegistry = () => {
  // LIST PAGE
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.NOTIFICATIONS,
    MASONRY_WIDGET_TYPES.NOTIFICATION_CARD,
    NotificationCard,
  );

  MasonryDriverActionController.registerHandler(
    MASONRY_ACTION_TYPES.DEEPLINK,
    MASONRY_NAMESPACES.NOTIFICATIONS,
    () => {},
  );

  // DETAILS PAGE
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.NOTIFICATION_DETAILS,
    MASONRY_WIDGET_TYPES.NOTIFICATION_DETAILS_CARD,
    NotificationDetailsRenderer,
  );
};
