import type { AppStoreLinkProps, ExternalLink } from 'common/types';
import { getLocaleConstant } from 'utils/get-locale-constant.util';
import { useIntl } from '@leagueplatform/locales';
import { EXTERNAL_URLS } from 'common/constants';
import APPLE_STORE_ICON from 'assets/apple-store-icon.png';
import APPLE_STORE_ICON_ES from 'assets/apple-store-icon-es.png';
import GOOGLE_STORE_ICON from 'assets/google-store-icon.png';
import GOOGLE_STORE_ICON_ES from 'assets/google-store-icon-es.png';
import OvatientExternalLinkIcon from 'assets/ovatient-external-link-icon-white.png';

export type Config = {
  footerLinks: ExternalLink[];
  appLinks: AppStoreLinkProps[];
};

export function useFooterConfig(): Config {
  const { formatMessage } = useIntl();

  const footerLinks: ExternalLink[] = [
    {
      to: EXTERNAL_URLS.ABOUT_METROHEALTH_SYSTEM,
      target: '_blank',
      text: formatMessage({ id: 'STR_ABOUT_METROHEALTH_SYSTEM' }),
    },
    {
      to: EXTERNAL_URLS.CONTACT_US,
      target: '_blank',
      text: formatMessage({ id: 'STR_CONTACT_US' }),
    },
    {
      to: EXTERNAL_URLS.TERMS_OF_SERVICE,
      target: '_blank',
      text: formatMessage({ id: 'STR_METROHEALTH_TERMS_AND_CONDITIONS' }),
    },
    {
      to: EXTERNAL_URLS.PRIVACY_POLICY,
      target: '_blank',
      text: formatMessage({ id: 'STR_METROHEALTH_PRIVACY_NOTICE' }),
    },
    {
      to: EXTERNAL_URLS.MYCHART_HOME,
      linkIcon: OvatientExternalLinkIcon,
      target: '_blank',
      text: formatMessage({ id: 'STR_GO_TO_MYCHART' }),
    },
  ];

  const appLinks: AppStoreLinkProps[] = [
    {
      href: EXTERNAL_URLS.APPLE_APP_STORE,
      alt: formatMessage({ id: 'STR_APPLE_APP_STORE_ICON_ALT' }),
      src: getLocaleConstant({
        en: APPLE_STORE_ICON,
        es: APPLE_STORE_ICON_ES,
      }),
    },
    {
      href: EXTERNAL_URLS.GOOGLE_PLAY,
      alt: formatMessage({ id: 'STR_GOOGLE_PLAY_ICON_ALT' }),
      src: getLocaleConstant({
        en: GOOGLE_STORE_ICON,
        es: GOOGLE_STORE_ICON_ES,
      }),
    },
  ];

  return {
    footerLinks,
    appLinks,
  };
}
